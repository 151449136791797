import React from "react";
import styled from "styled-components";
import Grid from "@tleef/react-grid";
import showdown from "showdown";
import { upperCase } from "upper-case";
import { PostComment } from "../backends/graph";

const converter = new showdown.Converter();

const Container = styled.div`
  display: flex;
  padding: 20px;
  border: 1px solid ${(props) => props.theme.color.primary.minus};
  border-radius: 2px;
  margin-bottom: 20px;
  width: 100%;
`;

const Avatar = styled.img`
  margin-right: 20px;
  height: 60px;
  width: 60px;
`;

const Name = styled.div`
  display: flex;
  color: ${(props) => props.theme.color.secondary.main};
  letter-spacing: 1px;
`;

const Text = styled.div`
  display: flex;
  color: ${(props) => props.theme.color.secondary.minus};
  margin: -14px 0;

  & > p:last-child {
    margin-bottom: 0;
  }
`;

interface Props {
  comment: PostComment;
}

export default ({ comment }: Props) => {
  return (
    <Container>
      <Avatar src={comment.avatar} />
      <Grid container spacing={8}>
        <Grid item xs={12}>
          <Name>{upperCase(comment.name)}</Name>
        </Grid>
        <Grid item xs={12}>
          <Text
            dangerouslySetInnerHTML={{
              __html: converter.makeHtml(comment.text),
            }}
          />
        </Grid>
      </Grid>
    </Container>
  );
};
