import React from "react";
import styled from "styled-components";
import {
  faFacebookF,
  faTwitter,
  faGooglePlusG,
} from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";

import SocialLink from "./SocialLink";
import { Post } from "../types";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 60px;
  width: 100%;
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const Title = styled.div`
  display: flex;
  justify-content: center;
  font-size: 30px;
  color: ${(props) => props.theme.color.secondary.main};
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  padding-bottom: 20px;
  border-bottom: 1px solid ${(props) => props.theme.color.secondary.main};
  margin-bottom: 40px;
`;

const Links = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

interface Props {
  post: Post;
}

const SharePost: React.FC<Props> = ({ post }) => {
  const url = `https://tleef.com${post.path}`;

  return (
    <Container>
      <TitleContainer>
        <Title>{"SHARE"}</Title>
      </TitleContainer>
      <Links>
        <SocialLink
          url={`http://www.facebook.com/sharer.php?u=${url}`}
          icon={faFacebookF}
          label={"Facebook"}
        />
        <SocialLink
          url={`http://twitter.com/share?url=${url}&text=${encodeURIComponent(
            post.title
          )}`}
          icon={faTwitter}
          label={"Twitter"}
        />
        <SocialLink
          url={`https://plus.google.com/share?url=${url}`}
          icon={faGooglePlusG}
          label={"Google+"}
        />
        <SocialLink
          url={`mailto:?subject=${encodeURIComponent(post.title)}&body=${url}`}
          icon={faEnvelope}
          label={"Mail"}
        />
      </Links>
    </Container>
  );
};

export default SharePost;
