import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 30px;
  width: 100%;
`;

const StyledLink = styled(Link)`
  font-weight: 600;
  color: ${(props) => props.theme.color.primary.minus};
  text-align: center;

  &:hover {
    color: ${(props) => props.theme.color.secondary.minus};
  }
`;

interface Props {
  prev?: string;
  next?: string;
}

const PrevNextPosts: React.FC<Props> = ({ prev, next }) => {
  return (
    <Container>
      {prev ? (
        <StyledLink to={prev.fields.path}>{"< PREV POST"}</StyledLink>
      ) : null}
      {next ? (
        <StyledLink to={next.fields.path}>{"NEXT POST >"}</StyledLink>
      ) : null}
    </Container>
  );
};

export default PrevNextPosts;
