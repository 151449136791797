import React from "react";
import styled from "styled-components";

import Comment from "./Comment";
import { Post } from "../types";
import { useGetPostCommentsQuery } from "../backends/graph/sdk";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 600px;
`;

interface Props {
  post: Post;
}

const CommentList: React.FC<Props> = ({ post }) => {
  const { data } = useGetPostCommentsQuery({
    variables: { post_id: post.id },
  });

  return (
    <Container>
      {data &&
        data.getPostComments &&
        data.getPostComments.edges &&
        data.getPostComments.edges.map((edge) => {
          if (!edge) {
            return;
          }

          return <Comment key={edge.node.id} comment={edge.node} />;
        })}
    </Container>
  );
};

export default CommentList;
