import React from "react";
import { Link, graphql, PageProps } from "gatsby";
import styled from "styled-components";
import dateFormat from "dateformat";
import { upperCase } from "upper-case";
import { upperCaseFirst } from "upper-case-first";
import { lowerCase } from "lower-case";
import { gray } from "@tleef/colors";

import Layout from "../components/Layout";
import Seo from "../components/Seo";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Content from "../components/Content";
import SharePost from "../components/SharePost";
import CommentSection from "../components/CommentSection";
import Category from "../components/CategoryLink";
import PrevNextPosts from "../components/PrevNextPosts";
import nodeToPost from "../utils/nodeToPost";
import { PageContext, PageData } from "../types";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Inner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const Article = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 30px;
  border-bottom: 1px solid ${(props) => props.theme.color.separator};
  margin-top: 40px;
  margin-bottom: 30px;
  width: 100%;
  max-width: 700px;
`;

const CategoryContainer = styled.div`
  margin-bottom: 15px;
`;

const TitleContainer = styled.div`
  margin-bottom: 25px;
`;

const Title = styled.div`
  display: inline-flex;
  color: ${(props) => props.theme.color.secondary.main};
  font-size: 30px;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 0.1rem;
  line-height: 1.1;
`;

const Body = styled.div`
  margin-bottom: 30px;
  color: ${(props) => props.theme.color.secondary.minus};

  & > p:last-child {
    margin-bottom: 0;
  }
`;

const MetaDataItem = styled(Link)`
  display: flex;
  color: ${(props) => props.theme.color.secondary.minus};
  font-size: 15px;
  text-transform: uppercase;
  letter-spacing: 1px;

  &:hover {
    color: ${(props) => props.theme.color.secondary.main};
  }
`;

const MetaData = styled.div`
  display: flex;

  & > ${MetaDataItem}:not(:last-child) {
    margin-right: 10px;
  }

  & > ${MetaDataItem}:not(:last-child):after {
    content: "-";
    color: ${gray[600]};
    margin-left: 10px;
  }
`;

export default function Post({
  data,
  pageContext,
}: PageProps<PageData, PageContext>) {
  const post = nodeToPost(data.markdownRemark);
  const { previous, next } = pageContext;

  return (
    <Layout>
      <Seo
        title={post.title}
        url={`https://tleef.com${post.path}`}
        description={post.description || post.excerpt}
        type={"article"}
        meta={[
          {
            name: `article:published_time`,
            content: dateFormat(
              new Date(post.posted_at * 1000),
              "UTC:yyyy-mm-dd"
            ),
          },
          {
            name: `article:author`,
            content: post.author,
          },
          {
            name: `article:section`,
            content: upperCaseFirst(post.category),
          },
        ]}
      />
      <Container>
        <Header />
        <Content>
          <Inner>
            <Article>
              <CategoryContainer>
                <Category to={`/blog/category/${lowerCase(post.category)}`}>
                  {upperCase(post.category)}
                </Category>
              </CategoryContainer>
              <TitleContainer>
                <Title>{post.title}</Title>
              </TitleContainer>
              <Body dangerouslySetInnerHTML={{ __html: post.html }} />
              <MetaData>
                <MetaDataItem to={"/about"}>{post.author}</MetaDataItem>
                <MetaDataItem as="div">
                  {dateFormat(
                    new Date(post.posted_at * 1000),
                    "UTC:mmmm dS, yyyy"
                  )}
                </MetaDataItem>
              </MetaData>
            </Article>
            <PrevNextPosts prev={previous} next={next} />
            <SharePost post={post} />
            <CommentSection post={post} />
          </Inner>
        </Content>
        <Footer />
      </Container>
    </Layout>
  );
}

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      excerpt(pruneLength: 320)
      fields {
        path
      }
      frontmatter {
        id
        title
        author
        category
        description
        posted_at
      }
    }
  }
`;
