import React from "react";
import styled from "styled-components";

import CommentBox from "./CommentBox";
import CommentList from "./CommentList";
import { Post } from "../types";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

interface Props {
  post: Post;
}

const CommentSection: React.FC<Props> = ({ post }) => {
  return (
    <Container>
      <CommentBox post={post} />
      <CommentList post={post} />
    </Container>
  );
};

export default CommentSection;
