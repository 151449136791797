import React, { useState } from "react";
import styled from "styled-components";
import shajs from "sha.js";
import Identicon from "identicon.js";
import Grid from "@tleef/react-grid";
import unique from "@tleef/unique-js";
import nowSeconds from "../utils/nowSeconds";
import { Post } from "../types";
import {
  GetPostCommentsDocument,
  GetPostCommentsQuery,
  GetPostCommentsQueryVariables,
  useCreatePostCommentMutation,
} from "../backends/graph/sdk";

const Container = styled.div`
  display: flex;
  padding: 20px;
  border: 1px solid ${(props) => props.theme.color.primary.minus};
  border-radius: 2px;
  margin-bottom: 20px;
  width: 100%;
  max-width: 600px;
`;

const Title = styled.div`
  display: flex;
  font-size: 20px;
  color: ${(props) => props.theme.color.secondary.main};
  letter-spacing: 0.1rem;
`;

const Display = styled.div`
  display: flex;
  color: ${(props) => props.theme.color.secondary.minus};
`;

const Label = styled.label`
  font-size: 14px;
  font-weight: 700;
  color: ${(props) => props.theme.color.secondary.main};
  margin-bottom: 5px;
`;

const Input = styled.input`
  transition: ${(props) => props.theme.transition.create()};
  font-size: 18px;
  color: ${(props) => props.theme.color.secondary.minus};
  background-color: ${(props) => props.theme.color.primary.plus};
  box-shadow: none;
  border: 1px solid ${(props) => props.theme.color.primary.minus};
  border-radius: 2px;
  padding: 6px 12px;
  outline: none;
  height: 45px;
  width: 100%;

  &:focus {
    border-color: ${(props) => props.theme.color.secondary.minus};
  }
`;

const TextArea = styled(Input)`
  height: 200px;
`;

const Button = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  transition: ${(props) => props.theme.transition.create()};
  background-color: ${(props) => props.theme.color.secondary.main};
  color: ${(props) => props.theme.color.primary.main};
  font-size: 16px;
  cursor: pointer;
  border-radius: 2px;
  height: 45px;
  width: 100%;

  &:hover {
    background-color: ${(props) => props.theme.color.secondary.plus};
  }
`;

const emailPattern = /.+@.+\..+/;

interface Props {
  post: Post;
}

const CommentBox: React.FC<Props> = ({ post }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [text, setText] = useState("");

  const [createPostComment] = useCreatePostCommentMutation();

  return (
    <Container>
      <Grid container spacing={24}>
        <Grid item xs={12}>
          <Title>{"LEAVE A REPLY"}</Title>
        </Grid>
        <Grid item xs={12}>
          <TextArea
            as={"textarea"}
            placeholder={"Tell me something"}
            value={text}
            onChange={(event) => setText(event.target.value)}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Input
            placeholder={"Your name"}
            value={name}
            onChange={(event) => setName(event.target.value)}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Input
            placeholder={"Your email address"}
            value={email}
            onChange={(event) => setEmail(event.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <Display>{"Your email address will not be published."}</Display>
        </Grid>
        <Grid item xs={12}>
          <Button
            onClick={async () => {
              if (!text || !name || !email || !emailPattern.test(email)) {
                return;
              }

              const id = unique();
              const hash = shajs("sha256").update(email).digest("hex");
              const identicon = new Identicon(hash, 240).toString();
              const avatar = `data:image/png;base64,${identicon}`;
              const now = nowSeconds();

              await createPostComment({
                variables: {
                  post_id: post.id,
                  text,
                  name,
                  email,
                },
                optimisticResponse: {
                  __typename: "Mutation",
                  createPostComment: {
                    __typename: "PostComment",
                    id,
                    post_id: post.id,
                    parent_id: post.id,
                    text,
                    name,
                    avatar: avatar,
                    updated_at: now,
                    created_at: now,
                  },
                },
                update: (proxy, { data }) => {
                  console.log("UPDATING");

                  if (!data || !data.createPostComment) {
                    return;
                  }

                  let queryData = proxy.readQuery<
                    GetPostCommentsQuery,
                    GetPostCommentsQueryVariables
                  >({
                    query: GetPostCommentsDocument,
                    variables: { post_id: post.id },
                  });

                  queryData = queryData || {};
                  queryData.getPostComments = queryData.getPostComments || {
                    __typename: "PostCommentConnection",
                    edges: [],
                    page_info: {
                      __typename: "PageInfo",
                      has_next_page: false,
                    },
                  };
                  queryData.getPostComments.edges =
                    queryData.getPostComments.edges || [];
                  queryData.getPostComments.edges.unshift({
                    __typename: "PostCommentEdge",
                    node: data.createPostComment,
                  });
                  proxy.writeQuery<
                    GetPostCommentsQuery,
                    GetPostCommentsQueryVariables
                  >({
                    query: GetPostCommentsDocument,
                    variables: { post_id: post.id },
                    data: queryData,
                  });
                },
              });

              setName("");
              setEmail("");
              setText("");
            }}
          >
            {"POST COMMENT"}
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};

export default CommentBox;
